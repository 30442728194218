<template>
  <navigation index="languageManages" ref="navigation">
    <div :style="{ height: ContentHeight + 'px' }">
      <div class="topnavigation">
        <el-button @click="AddLanguages({})" style="margin-left: 10px">
          新增
        </el-button>
      </div>
      <el-table
        :data="LanguagesList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        default-expand-all
      >
        <el-table-column prop="Code" label="编号" width="180" />
        <el-table-column prop="Name" label="名称" width="180" />
        <el-table-column label="图片" width="100">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-image
                width="60"
                height="60"
                :src="scope.row.ImageFormat"
                class="avatar"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Default" label="默认语言" width="180">
          <template #default="scope">
            <span> {{ scope.row.Default }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="Default" label="编辑语言" width="300">
          <template #default="scope">
            <el-button @click="AddLanguages(scope.row)"> 修改 </el-button>
            <el-button
              type="primary"
              @click="EditLanguageContentWindow(scope.$index, scope.row)"
              >编辑语言值</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </navigation>

  <!-- 编辑语言 -->
  <el-dialog title="语言编辑" v-model="LanguageOptVisible" width="800px">
    <el-form
      ref="form"
      label-position="right"
      label-width="140px"
      style="margin-top: 10px"
    >
      <el-form-item label="语言名称">
        <el-input type="text" v-model="LanguagesOpt.Name" />
      </el-form-item>
      <el-form-item label="语言编号">
        <el-input type="text" v-model="LanguagesOpt.Code" />
      </el-form-item>
      <el-form-item label="默认">
        <el-switch v-model="LanguagesOpt.Default" />
      </el-form-item>

      <el-form-item label="封面图">
        <el-upload
          class="avatar-uploader"
          :action="UploadPictureImageUrl"
          :show-file-list="false"
          :on-success="UploadSuccess"
          :before-upload="UploadSuccess"
          :headers="headersImage"
        >
          <img
            v-if="LanguagesOpt.ImageFormat"
            :src="LanguagesOpt.ImageFormat"
            class="avatar"
          />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="LanguageOptVisible = false">关闭</el-button>
        <el-button type="primary" @click="InsertLanguages()">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 编辑 -->
  <el-dialog
    title="语言列表"
    v-model="LanguageContentWindowVisible"
    width="1200px"
  >
    <div style="margin-top: 5px">
      <el-input
        v-model="LanguageKeyValue"
        placeholder="请输入语言Key"
        style="width: 300px"
      >
        <template #append>
          <el-button @click="GetLanguageResource"> 查询 </el-button>
        </template>
      </el-input>
      <el-button
        @click="EditLanguageResourceWindow(0, {})"
        style="margin-left: 20px"
        >新增</el-button
      >
      <el-button @click="OpenBaiduTranslate()" style="margin-left: 20px"
        >自动翻译</el-button
      >
    </div>
    <br />
    <el-form ref="form" label-position="right" label-width="140px">
      <el-table
        height="500px"
        :data="LanguageResourceList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        default-expand-all
      >
        <el-table-column prop="ResourceName" label="主键" width="280" />
        <el-table-column prop="ResourceValue" label="内容" />
        <el-table-column width="180" label="操作">
          <template #default="scope">
            <el-button
              size="small"
              type="primary"
              @click="EditLanguageResourceWindow(0, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="small"
              type="danger"
              @click="DeleteLanguageResource(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <template #footer>
      <div style="display: flex; width: 100%; justify-content: space-between">
        <div>
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="CurrentChange"
            :page-size="padding.Size"
            :total="padding.Count"
          >
          </el-pagination>
        </div>
        <div style="">
          <el-button
            type="primary"
            @click="LanguageContentWindowVisible = false"
            >关闭</el-button
          >
        </div>
      </div>
    </template>
  </el-dialog>

  <div></div>

  <!-- //编辑的小窗口 -->
  <el-dialog title="编辑" v-model="LanguageResourceEditVisible" width="800px">
    <el-form label-width="120px" style="margin-top: 5px">
      <el-form-item label="所属语言">
        <el-input disabled type="text" v-model="LanguagesOpt.Name" />
      </el-form-item>
      <el-form-item label="健">
        <el-input type="text" v-model="LanguageResourceOpt.ResourceName" />
      </el-form-item>

      <el-form-item label="值">
        <el-input type="text" v-model="LanguageResourceOpt.ResourceValue" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="LanguageResourceEditVisible = false">关闭</el-button>
        <el-button type="primary" @click="EditLanguageResource()"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>

  <!-- 选择转换语言的参数 -->
  <el-dialog title="目标语言" v-model="OpenBaiduTranslateWindow" width="800px">
    <div
      style=" height: 300px;  overflow: auto; "
    >
    <div style="   display: flex;
        gap: 10px;
        flex-wrap: wrap;        justify-items: auto;">
      <el-button @click="BaiduTranslate('中文	zh', 'zh')"> 中文 zh </el-button>
      <el-button @click="BaiduTranslate('英语', 'en')"> 英语 en </el-button>
      <el-button @click="BaiduTranslate('日语', 'jp')"> 日语 jp </el-button>
      <el-button @click="BaiduTranslate('法语', 'fra')"> 法语 fra </el-button>
      <el-button @click="BaiduTranslate('西班牙语', 'spa')">
        西班牙语 spa
      </el-button>
      <el-button @click="BaiduTranslate('意大利语', 'it')">
        意大利语 it
      </el-button>
      <el-button @click="BaiduTranslate('波兰语', 'pl')"> 波兰语 pl </el-button>
      <el-button @click="BaiduTranslate('丹麦语', 'dan')">
        丹麦语 dan
      </el-button>
      <el-button @click="BaiduTranslate('捷克语', 'fin')">
        fin 捷克语
      </el-button>
      <el-button @click="BaiduTranslate('芬兰语', 'fin')">
        芬兰语 fin
      </el-button>
      <el-button @click="BaiduTranslate('瑞典语', 'swe')">
        瑞典语 swe
      </el-button>
      <el-button @click="BaiduTranslate('匈牙利语', 'hu')">
        匈牙利语 hu
      </el-button>
      <el-button @click="BaiduTranslate('葡萄牙语', 'pt')">
        葡萄牙语 pt
      </el-button>
      <el-button @click="BaiduTranslate('保加利亚语', 'bul')">
        保加利亚语 bul
      </el-button>

      <!-- 中文	zh		
 日语	jp
韩语	kor	法语	fra	西班牙语	spa
泰语	th	阿拉伯语	ara	俄语	ru
葡萄牙语	pt	德语	de	意大利语	it
希腊语	el	荷兰语	nl	波兰语	pl
保加利亚语	bul	爱沙尼亚语	est	丹麦语	dan
芬兰语	fin	捷克语	cs	罗马尼亚语	rom
斯洛文尼亚语	slo	瑞典语	swe	匈牙利语	hu
繁体中文	cht	越南语	vie	 	 -->
    </div>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="OpenBaiduTranslateWindow = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js";
import navigation from "../../../components/Modules/AdminNavigation.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import ProductItem from "../../../components/ProductItem.vue";
import {
  Menu,
  Expand,
  Avatar,
  Wallet,
  Download,
  Delete,
  Search,
} from "@element-plus/icons-vue";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      TableHeight: document.documentElement.clientHeight - 120,
      ContentHeight: document.documentElement.clientHeight - 200,

      activeName: "",
      LanguagesList: [], // 语言种类
      LanguagesOpt: {}, // 当前选中语言

      LanguageResourceList: [],
      LanguageResourceOpt: {}, // 当前操作语言小项

      LanguageContentWindowVisible: false, // 语言内容窗口
      LanguageResourceEditVisible: false, // 语言内容小窗口

      LanguageKeyValue: "", // 筛选值

      LanguageOptVisible: false, //语言编辑

      headersImage: {},
      UploadPictureImageUrl: "",
      OpenBaiduTranslateWindow: false,

      padding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
    };
  },
  name: "App",
  mounted() {
    this.GetLanguagesList();
    this.UploadPictureImageUrl = axios.GetUrl() + "/api/Upload/ImageFileWebp";
    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.TableHeight = document.documentElement.clientHeight - 120;
        this.ContentHeight = document.documentElement.clientHeight - 200;
        this.$refs.navigation.onresize();
      })();
    };
    this.TableHeight = document.documentElement.clientHeight - 120;
    this.ContentHeight = document.documentElement.clientHeight - 200;
  },
  components: {
    ProductItem,
    Search,
    navigation,
  },
  methods: {
    OpenBaiduTranslate() {
      this.OpenBaiduTranslateWindow = true;
    },
    //调用翻译
    BaiduTranslate(title, code) {
      ElMessageBox.confirm(
        "您确定要自动翻译" +
          this.LanguagesOpt.Name +
          "成" +
          title +
          "语言吗 <br /> <span style='color:red'>  只会翻译内容为空的语言！  </span>",
        "Warning",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          dangerouslyUseHTMLString: true,
          type: "warning",
          title: "提示",
        }
      )
        .then(() => {
          axios.apiMethod(
            "/admins/Languages/AutoBaiduTranslate",
            "post",
            {
              LanguageId: this.LanguagesOpt.Id,
              ResourceName: this.LanguageKeyValue,
              LanguageCode: code,
            },
            (result) => {
              alert("共自动翻译了：" + result.Data.Count + "个");
            }
          );
        })
        .catch(() => {});
    },
    // 上传成功操作
    UploadSuccess(response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName;
        this.ComImageFormet = response.Data.ImageFormet;
        this.LanguagesOpt.ImageFormat = response.Data.ImageFormet;
        this.LanguagesOpt.Image = response.Data.FileName;
      }
    },
    //删除语言
    DeleteLanguageResource(item) {
      ElMessageBox.confirm(
        "您确定要删除语言吗 <span style='color:red'>" +
          item.ResourceName +
          "</span>",
        "Warning",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          dangerouslyUseHTMLString: true,
          type: "warning",
          title: "提示",
        }
      )
        .then(() => {
          axios.apiMethod(
            "/admins/Languages/DeleteLanguageResource?masterKey=" +
              item.ResourceName,
            "get",
            {},
            (result) => {
              this.GetLanguageResource();
            }
          );
        })
        .catch(() => {});
    },
    //插入语言
    InsertLanguages() {
      axios.apiMethod(
        "/admins/Languages/InsertLanguage",
        "post",
        this.LanguagesOpt,
        (result) => {
          ElMessage({
            message: "操作成功",
            type: "success",
          });
          this.LanguageOptVisible = false;
          this.GetLanguagesList();
        }
      );
    },

    //新增语言
    AddLanguages(item) {
      this.LanguageOptVisible = true;
      this.LanguagesOpt = item;
    },
    //编辑语言内容
    EditLanguageResource() {
      this.LanguageResourceOpt.LanguageId = this.LanguagesOpt.Id;
      axios.apiMethod(
        "/admins/Languages/EditLanguageResource",
        "post",
        this.LanguageResourceOpt,
        (result) => {
          this.LanguageResourceEditVisible = false;
          ElMessage({
            message: "操作成功",
            type: "success",
          });
          this.GetLanguageResource();
        }
      );
    },
    // 新增语言内容
    EditLanguageResourceWindow(index, item) {
      this.LanguageResourceEditVisible = true;
      this.LanguageResourceOpt = item;
    },

    // 打开语言页面
    EditLanguageContentWindow(index, item) {
      this.LanguagesOpt = item;
      this.LanguageContentWindowVisible = true;
      this.GetLanguageResource();
    },
    //切换页码
    CurrentChange(number) {
      this.padding.Page = number;
      this.GetLanguageResource();
    },
    // 获取语言
    GetLanguageResource() {
      this.padding.Where = {
        LanguageId: this.LanguagesOpt.Id,
        ResourceName: this.LanguageKeyValue,
      };
      axios.apiMethod(
        "/admins/Languages/GetLanguageResource",
        "post",
        this.padding,
        (result) => {
          if (result.Code == 200) {
            this.LanguageResourceList = result.Data.Datas;
            this.padding.Count = result.Data.Count;
          }
        }
      );
    },

    // 编辑框
    PaymethodEditWindow(item) {
      this.PaymethodEditVisible = true;
      this.PaymethodOpt = item;
    },

    // 跳转到支付配置页面
    SettingPaymethod(item) {
      this.$router.push({
        path: item.SettingRouter,
        // name: 'mallList',
        query: {},
      });
    },
    // 获取语言列表
    GetLanguagesList(item) {
      axios.apiMethod("/admins/Languages/GetLanguages", "get", {}, (result) => {
        if (result.Code == 200) {
          this.LanguagesList = result.Data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
